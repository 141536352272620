<template>
  <v-row>
    <v-dialog
      v-model="shower"
      persistent
      max-width="500"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-text class="pa-0">
          <v-overlay :value="loadprogress" absolute :color="color.BG" dark>
              <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
              <!-- <span :style="colorProgress">&nbsp; loading</span> -->
              </v-overlay>
          <v-list class=" pa-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title style="font-size:large;">
                  Map Drive
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon size="35" dense dark :color="color.theme"
                  >mdi-server-network</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <!-- คู่มือ -->
        <v-layout>
          <v-flex class="text-right">
            <v-btn small class="ma-2 white--text" :color="color.theme" @click="fn_gotomanual()">
              <template v-slot:prepend>
                <v-icon color="success"></v-icon>
              </template>
             {{ $t('Usermanual')}}
            </v-btn>
         </v-flex>
        </v-layout>
        <v-tabs
          v-model="tab"
          bg-color="primary"
          v-if="status_mapdrive === '0' || status_mapdrive === '1' || status_mapdrive === '4' "
        >
        <v-tab value="one">{{$t("toolbar.mydrive_bu")}}</v-tab>
        <v-tab value="two">{{$t("toolbar.mydrive")}}</v-tab>
          <v-tab-item>
            <v-card-text v-if="status_mapdrive === '0' || status_mapdrive === '1' || status_mapdrive === '4' " style="height:350px">
              <v-layout row wrap align-center justify-center>
                <v-flex xs11 lg11>
                  <v-card class="elevation-0">
                    <v-layout row wrap justify-center>
                      <!-- <v-flex xs12 sm12 md12 lg12>
                        <v-switch
                          v-model="mapdrive"
                          hide-details
                          inset
                          :label="mapdrive === true ? $t('openmapdrive') : $t('closemapdrive')"
                        ></v-switch>
                      </v-flex> -->
                      <v-flex xs12 sm12 md12 lg12 class="mt-4" v-if="mapdrive === true">
                        <v-text-field
                          id="textfield5"
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="Domain_map_business"
                          :label="
                            $t('default') === 'th' ? 'โดเมน(Windows)' : 'Mapdrive Domain(Windows)'
                          "
                          prepend-icon="mdi-microsoft"
                          append-icon="mdi-clipboard-outline"
                          @click:append="fn_copy(5)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 v-if="mapdrive === true">
                        <v-text-field
                          id="textfield6"
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="Domain_map_macos_business"
                          :label="
                            $t('default') === 'th' ? 'โดเมน(Mac OS)' : 'Mapdrive Domain(Mac OS)'
                          "
                          prepend-icon="mdi-apple"
                          append-icon="mdi-clipboard-outline"
                          @click:append="fn_copy(6)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 v-if="mapdrive === true">
                        <v-text-field
                          id="textfield7"
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="username_map"
                          :label="
                            $t('default') === 'th' ? 'ชื่อผู้ใช้งาน' : 'Username'
                          "
                          prepend-icon="mdi-account-circle"
                          append-icon="mdi-clipboard-outline"
                          @click:append="fn_copy(7)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 v-if="mapdrive === true">
                        <v-text-field
                          id="textfield8"
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="password_map"
                          :label="$t('default') === 'th' ? 'รหัสผ่าน' : 'Password'"
                          prepend-icon="vpn_key"
                          :type="showpassword ? 'text' : 'password'"
                          >
                          <!-- @click:append="showpassword = !showpassword" -->
                          <!-- :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" -->
                        <template slot="append">
                          <v-icon @click="showpassword = !showpassword"> {{showpassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                          <v-icon @click="fn_copy(8)">mdi-clipboard-outline</v-icon>
                        </template>
                      </v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text v-if="status_mapdrive === '0' || status_mapdrive === '1' || status_mapdrive === '4' " style="height:350px">
              <v-layout row wrap align-center justify-center>
                <v-flex xs11 lg11>
                  <v-card class="elevation-0">
                    <v-layout row wrap justify-center>
                      <!-- <v-flex xs12 sm12 md12 lg12>
                        <v-switch
                          v-model="mapdrive"
                          hide-details
                          inset
                          :label="mapdrive === true ? $t('openmapdrive') : $t('closemapdrive')"
                        ></v-switch>
                      </v-flex> -->
                      <v-flex xs12 sm12 md12 lg12 class="mt-4" v-if="mapdrive === true">
                        <v-text-field
                          id="textfield1"
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="Domain_map"
                          :label="
                            $t('default') === 'th' ? 'โดเมน(Windows)' : 'Mapdrive Domain(Windows)'
                          "
                          prepend-icon="mdi-microsoft"
                          append-icon="mdi-clipboard-outline"
                          @click:append="fn_copy(1)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 v-if="mapdrive === true">
                        <v-text-field
                          id="textfield2"
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="Domain_map_macos"
                          :label="
                            $t('default') === 'th' ? 'โดเมน(Mac OS)' : 'Mapdrive Domain(Mac OS)'
                          "
                          prepend-icon="mdi-apple"
                          append-icon="mdi-clipboard-outline"
                          @click:append="fn_copy(2)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 v-if="mapdrive === true">
                        <v-text-field
                          id="textfield3"
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="username_map"
                          :label="
                            $t('default') === 'th' ? 'ชื่อผู้ใช้งาน' : 'Username'
                          "
                          prepend-icon="mdi-account-circle"
                          append-icon="mdi-clipboard-outline"
                          @click:append="fn_copy(3)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 v-if="mapdrive === true">
                        <v-text-field
                          id="textfield4"
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="password_map"
                          :label="$t('default') === 'th' ? 'รหัสผ่าน' : 'Password'"
                          prepend-icon="vpn_key"
                          :type="showpassword ? 'text' : 'password'"
                          >
                          <!-- @click:append="showpassword = !showpassword" -->
                          <!-- :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" -->
                        <template slot="append">
                          <v-icon @click="showpassword = !showpassword"> {{showpassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                          <v-icon @click="fn_copy(4)">mdi-clipboard-outline</v-icon>
                        </template>
                      </v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
        <v-card-text v-if="status_mapdrive === '3'" style="height:300px">
              <v-layout row wrap align-center justify-center>
                <v-flex xs11 lg11 ma-5>
                  <v-card class="elevation-0">
                    <v-layout row wrap justify-center>
                        <v-flex xs12 sm12 md12 lg12>
                        <v-text-field
                          readonly
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="username_map"
                          :label="
                            $t('default') === 'th' ? 'ชื่อผู้ใช้งาน' : 'Username'
                          "
                          prepend-icon="mdi-account-circle"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-text-field
                          clearable
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="password"
                          :label="$t('default') === 'th' ? 'รหัสผ่าน' : 'Password'"
                          prepend-icon="vpn_key"
                          @click:append="showpassword = !showpassword"
                          :type="showpassword ? 'text' : 'password'"
                          :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="$t('default') === 'th' ? required_ : required_eng"
                          :error-messages="
                            $t('default') === 'th'
                              ? passwordError
                              : passwordError_eng
                          "
                          required
                          @input="$v.password.$touch()"
                          @blur="$v.password.$touch()"
                          persistent-hint
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-text-field
                          clearable
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="confirmpassword"
                          :label="
                            $t('default') === 'th'
                              ? 'ยืนยันรหัสผ่าน'
                              : 'ConfirmPassword'
                          "
                          prepend-icon="vpn_key"
                          @click:append="showpassword = !showpassword"
                          :type="showpassword ? 'text' : 'password'"
                          :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="
                            $t('default') === 'th'
                              ? requiredpassword
                              : requiredpassword_en
                          "
                          :error-messages="
                            $t('default') === 'th'
                              ? confirmpasswordError
                              : confirmpasswordError_eng
                          "
                          required
                          @input="$v.confirmpassword.$touch()"
                          @blur="$v.confirmpassword.$touch()"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
        </v-card-text>
        <v-card-text v-if="status_mapdrive === '2'" style="height:100px">
            <div class="text-center">
              <br />
              <span style="font-size: 16px;" class="ml-4 text-center">
                {{ $t('blockmapdrive')}}
              </span>   
              <br/>    
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-progress-circular
            v-if="isloadingprogress === true"
              color="teal"
              indeterminate
              width="2"
              size="20"
            >
          </v-progress-circular>
          <v-spacer></v-spacer>
          <v-btn v-if="status_mapdrive === '2'" color="red" outlined @click="$emit('closedialog'),fn_cleardata()" :disable="isloadingprogress">{{$t("tagVersion.closebtn")}}</v-btn>
          <v-btn v-else color="red" outlined @click="$emit('closedialog'),fn_cleardata()" :disable="isloadingprogress">{{$t("checkpassword.close")}}</v-btn>
          <!-- <v-btn
            v-if="status_mapdrive === '0' || status_mapdrive === '1' "
            class="white--text"
            :color="color.theme"
            @click="fn_update_mapdrive()"
            :disable="isloadingprogress"
            >{{$t('confirm.confirm')}}</v-btn
          > -->
          <v-btn
            v-if="status_mapdrive === '3'"
            class="white--text"
            :color="color.theme"
            :disabled="
              confirmpassword !== password ||
              confirmpassword === '' ||
              password === ''
                ? true
                : false
            "
            :disable="isloadingprogress"
            @click="fn_update_mapdrive()"
            >{{$t('confirm.confirm')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
import axios from "axios";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    confirmpassword: { required },
    password: { required },
  },
  components: {},
  props: ["show"],
  data() {
    return {
      loadprogress:true,
      isloadingprogress:false,
      Domain_map: "",
      Domain_map_macos:"",
      Domain_map_macos_business:"",
      Domain_map_business:"",
      username_map: "",
      password_map:"",
      mapdrive: false,
      status_mapdrive: "",
      confirmpassword: "",
      password: "",
      username_show: "",
      showpassword: false,
      requiredconfirmpassword: [ 
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) => this.checkpass(value) || "รหัสผ่านไม่ตรงกัน",
      ],
      requiredconfirmpassword_eng: [
        (value) => !!value || "Please fill data",
        (value) => this.checkpass(value) || "Passwords do not match",
      ],
      required_: [(value) => !!value || "โปรดระบุข้อมูล.",
      (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters",
      ],
      required_eng: [(value) => !!value || "Please fill data",
      (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters",
      ],
      requiredpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters",
        (value) => this.checkpass(value) || "รหัสผ่านไม่ตรงกัน",
      ],
      requiredpassword_en: [
        (value) => !!value || "Please fill data",
        (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters",
        (value) => this.checkpass(value) || "Passwords do not match",
      ],
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataOneAccountId",
    ]),
    shower: {
      get() {
        console.log("this.show ", this.show);
        if (this.show === true) {
          this.getdata();
        }
        return this.show;
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    title() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    textfield() {
      return "min-height: 1px;";
    },
    success() {
      return "color:#58A144; font-size: 18px; line-height: 24px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 500;
      } else {
        return 346;
      }
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push("โปรดระบุรหัสผ่าน / ต้องมีอักขระอย่างน้อย 8 ตัว");
      return errors;
    },
    passwordError_eng() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push("Please fill Password / Must have at least 8 characters");
      return errors;
    },
    confirmpasswordError() {
      const errors = [];
      if (!this.$v.confirmpassword.$dirty) return errors;
      !this.$v.confirmpassword.required &&
        errors.push("โปรดยืนยันรหัสผ่าน / ต้องมีอักขระอย่างน้อย 8 ตัว");
      return errors;
    },
    confirmpasswordError_eng() {
      const errors = [];
      if (!this.$v.confirmpassword.$dirty) return errors;
      !this.$v.confirmpassword.required &&
        errors.push(
          "Please fill Confirm Password / Must have at least 8 characters"
        );
      return errors;
    },
  },
  methods: {
    fn_copy(item){
      console.log("item",item);
      let input
      if(item === 1){
        input = document.getElementById("textfield1");
      }else if(item === 2){
        input = document.getElementById("textfield2");
      }else if(item === 3){
        input = document.getElementById("textfield3");
      }else if(item === 4){
        input = document.getElementById("textfield4");
      }else if(item === 5){
        input = document.getElementById("textfield5");
      }else if(item === 6){
        input = document.getElementById("textfield6");
      }else if(item === 7){
        input = document.getElementById("textfield7");
      }else if(item === 8){
        input = document.getElementById("textfield8");
      }
      input.select();
      document.execCommand("copy");
      Toast.fire({
        icon: "success",
        title: this.$t("copysuccess"),
      });
    },
    fn_checkSpecialChar_(value) {
      let allow = true;
      let specialChar = [" ", '"', "'"];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      this.allowcheckpassword = allow;
      console.log("allow", allow);
      return allow;
    },
    async getdata() {
      console.log("localStorage.getItem", VueCookies.get("username"));
      this.username_show = this.decryptdata(VueCookies.get("username"));

      let payload = {
        account_id: this.dataAccountId,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/mapdrive/get_info",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("response", response);
          this.rootfile = [];
          if (response.data.status === "OK") {
            this.loadprogress = false;
            this.status_mapdrive = response.data.data.status_mapdrive;
            if(this.status_mapdrive === '0'){
                this.mapdrive = true;
                this.username_map = response.data.data.username;
                this.password_map = response.data.data.password;
                this.Domain_map = response.data.data.mapdrive_domain;
                this.Domain_map_macos = response.data.data.mapdrive_domain_macos;
                this.Domain_map_business = response.data.data.mapdrive_domain_business;
                this.Domain_map_macos_business = response.data.data.mapdrive_domain_business_macos;
            }else if(this.status_mapdrive === '1'){
                this.mapdrive = true;
                this.username_map = response.data.data.username;
                this.password_map = response.data.data.password;
                this.Domain_map = response.data.data.mapdrive_domain;
                this.Domain_map_macos = response.data.data.mapdrive_domain_macos;
                this.Domain_map_business = response.data.data.mapdrive_domain_business;
                this.Domain_map_macos_business = response.data.data.mapdrive_domain_business_macos;
            }else if(this.status_mapdrive === '3'){
                this.username_map = response.data.data.username;
            }
          } else {
            this.loadprogress = false;
            // this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.loadprogress = false;
          //   this.loaddataprogress = false;
          console.log("err", error);
          Toast.fire({
            icon: "error",
            title: error.response.data.errorMessage,
          });
        });
    },
    async fn_update_mapdrive(){
        this.isloadingprogress = true;
        let payload;
        if(this.status_mapdrive === '3'){
          payload = {
            account_id: this.dataAccountId,
            handle_user: "4",
            password: this.password
          };
        }else{
          payload = {
            account_id: this.dataAccountId,
            handle_user: this.mapdrive === true ? "1" : "0",
          };
        }
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/mapdrive/handle_user",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("response", response);
          this.rootfile = [];
          if (response.data.status === "OK") {
            this.isloadingprogress = false;
            Toast.fire({
              icon: "success",
              title: "setting success",
            });
            // this.$emit('closedialog');
            this.fn_open_mapdrive();
            // this.getdata();
          } else {
            // this.loaddataprogress = false;
            this.isloadingprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          //   this.loaddataprogress = false;
          this.isloadingprogress = false;
          console.log("err", error);
          Toast.fire({
            icon: "error",
            title: error.response.data.errorMessage,
          });
        });
    },
    async fn_open_mapdrive(){
        this.isloadingprogress = true;
        let payload;
          payload = {
            account_id: this.dataAccountId,
            handle_user: "1",
          };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/mapdrive/handle_user",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("response", response);
          this.rootfile = [];
          if (response.data.status === "OK") {
            this.isloadingprogress = false;
            Toast.fire({
              icon: "success",
              title: "setting success",
            });
            // this.$emit('closedialog');
            this.getdata();
          } else {
            // this.loaddataprogress = false;
            this.isloadingprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          //   this.loaddataprogress = false;
          this.isloadingprogress = false;
          console.log("err", error);
          Toast.fire({
            icon: "error",
            title: error.response.data.errorMessage,
          });
        });
    },
    checkpass(value) {
      if (value === this.password) {
        return true;
      } else {
        return false;
      }
    },
    decryptdata(data) {
      // ใข้งานอยู่
      try {
        var data_decrypt = CryptoJS.AES.decrypt(
          data,
          CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
          { mode: CryptoJS.mode.ECB }
        );
        data_decrypt = window.atob(data_decrypt.toString(CryptoJS.enc.Utf8));
        return data_decrypt;
      } catch (err) {
        return "error";
      }
    },
    fn_cleardata(){
      this.password = '';
      this.confirmpassword = '';
    },
    fn_gotomanual(){
      window.open(
          "https://onebox.thaimediafund.or.th/public?id=4ff510bc25d5690a7b6daa816327a7a3"
        );
    }
  },
};
</script>
